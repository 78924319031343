












































































































import { formatDate, formatEvaluationTime } from '@/utils/helpers'
import { defineComponent, reactive } from '@vue/composition-api'
import type { Header } from '@/components/composables/useDataTable/types'
import { useDataTable } from '@/components/composables/useDataTable'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import CreateButton from '@/components/buttons/CreateButton.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import TenderService from '@/services/tender'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import EvaluateButton from '@/components/buttons/EvaluateButton.vue'
import ShowButton from '@/components/buttons/ShowButton.vue'
import PublishButton from '@/components/buttons/PublishButton.vue'
import ChooseClientButton from '@/components/buttons/ChooseClientButton.vue'
import TendersSearchFilters from '@/views/Tender/partials/TendersSearchFilters.vue'
import { ProcurementStatus } from '@/services/myTender/types'
import StatItem from '@/components/Stats/StatItem.vue'

export default defineComponent({
  name: 'TendersList',
  setup() {
    const searchParams = reactive({ filters: { startDate: undefined, endDate: undefined, status: undefined } })
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } = useDataTable(
      TenderService,
      searchParams,
    )

    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      formatDate,
      formatEvaluationTime,
      TenderService,
      searchParams,
      ProcurementStatus,
    }
  },
  components: {
    PublishButton,
    EvaluateButton,
    ShowButton,
    TenderStatus,
    DeleteButton,
    EditButton,
    CreateButton,
    TendersSearchFilters,
    StatItem,
    ChooseClientButton,
  },
  props: {
    tendersType: {
      type: String,
      default: '',
    },
  },
  computed: {
    headers(): Array<Header> {
      return [
        { value: 'name', text: this.$t('tenderName'), sortable: true },
        { value: 'publishDate', text: this.$t('publishDate'), sortable: true },
        { value: 'endDate', text: this.$t('closureDate'), sortable: true },
        { value: 'offerValidityMinutes', text: this.$t('evaluationDuration'), sortable: false },
        { value: 'status', text: this.$t('status'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false },
      ]
    },
    redirectedFrom(): string {
      if (this.tendersType === 'buy') return 'BuyingTenders'
      if (this.tendersType === 'sell') return 'SellingTenders'
      return ''
    },
    tendersListTitle() {
      if (this.tendersType === 'buy') return 'buyingTenders'
      if (this.tendersType === 'sell') return 'sellingTenders'
      return ''
    },
    tendersListButtonText() {
      if (this.tendersType === 'buy') return 'addBuyingTender'
      if (this.tendersType === 'sell') return 'addSellingTender'
      return ''
    },
    evaluationToRoute() {
      if (this.tendersType === 'buy') return 'EvaluateBuyingTender'
      if (this.tendersType === 'sell') return 'EvaluateSellingTender'
      return ''
    },
  },
  methods: {
    canAssignClientForLimited(isLimited: boolean) {
      return isLimited
    },
    isViewable(status: ProcurementStatus) {
      const viewableStatuses = [
        ProcurementStatus.CLOSED,
        ProcurementStatus.APPROVED,
        ProcurementStatus.PUBLISHED,
        ProcurementStatus.EVALUATING_EXPIRED,
        ProcurementStatus.DECRYPTING_OFFERS,
      ]
      return viewableStatuses.includes(status)
    },
  },
})



















import Vue from 'vue'
import { ClientDTO } from '@/services/client/types'
import ClientService from '@/services/client'

interface ClientData {
  id?: number
  clientId?: number
  name: string
}

export default Vue.extend({
  name: 'ClientSelect',
  props: {
    clientId: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  model: { prop: 'clientId' },
  data: function (): {
    rules: { required: (value: string) => string | boolean }
    clients: ClientData[]
    loading: boolean
  } {
    return {
      rules: {
        required: (value: string) => !!value || this.$tc('validation.required'),
      },
      clients: [],
      loading: false,
    }
  },
  async mounted(): Promise<void> {
    this.loading = true
    try {
      const { data } = await ClientService.getAllNoPagination()
      console.log('clientSelect data', data)
      if (data) {
        this.clients = data.data.items.map((client: ClientDTO) => ({
          id: client.id,
          name: client.companyName + ' - ' + client.administrator,
        }))
      }
    } finally {
      this.loading = false
    }
  },
  computed: {
    computedClient: {
      get(): number {
        return this.clientId
      },
      set(value: number): void {
        this.$emit('input', value)
      },
    },
  },
})

































import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  name: 'DatePicker',
  data() {
    return {
      show: false,
    }
  },
  props: {
    date: { type: String, default: '' },
    textFieldClass: { type: String, default: '' },
    min: { type: String, default: '' },
    max: { type: String, default: '' },
    closeOnContentClick: { type: Boolean, default: false },
  },
  model: { prop: 'date' },
  computed: {
    computedDate: {
      get(): string {
        return this.date
      },
      set(value: string): void {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    min: function () {
      if (this.min && !(this.min === '')) {
        const minDate = moment(this.min, 'YYYY-MM-DD')
        const date = moment(this.computedDate, 'YYYY-MM-DD')
        if (minDate.isAfter(date)) this.computedDate = ''
      }
    },
    max: function () {
      if (this.max && !(this.max === '')) {
        const maxDate = moment(this.max, 'YYYY-MM-DD')
        const date = moment(this.computedDate, 'YYYY-MM-DD')
        if (maxDate.isBefore(date)) this.computedDate = ''
      }
    },
  },
})

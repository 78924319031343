


























































import Vue from 'vue'
import TenderService from '@/services/tender'
import CardTitle from '@/components/CardTitle.vue'
import { errorMessages } from '@/utils/helpers'
import ClientSelect from '@/components/selects/ClientSelect.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { ProcurementStatus } from '@/services/myTender/types'

type ClientData = {
  clientId: number
  client: string
}

export default Vue.extend({
  name: 'ChooseClientButton',
  components: { CardTitle, ClientSelect, SubmitButton, CancelButton },
  model: { prop: 'showModal' },
  props: {
    procurementId: {
      type: Number,
      required: true,
      default: 0,
    },
    procurementStatus: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    dialog: false,
    clientId: 0,
    assignedClients: [] as ClientData[],
  }),
  computed: {
    canAssignNewClients(): boolean {
      return this.procurementStatus === ProcurementStatus.DRAFT
    },
  },
  methods: {
    async assignClient(): Promise<void> {
      if (this.clientId === 0 || this.clientId === null) {
        this.$toast.error(this.$t('chooseClient') as string)
        return
      }

      const loader = this.$loading.show()
      try {
        const response = await TenderService.assignLimitedClient(this.procurementId, this.clientId)
        if (response) {
          this.$emit('created')
          this.$toast.success(response.data.message)
          loader.hide()
          this.dialog = false
          await this.fetchAssignedClients()
        }
      } catch (err: any) {
        // eslint-disable-line
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    async unassignClient(clientId: number): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await TenderService.deleteLimitedClient(this.procurementId, clientId)
        if (response) {
          this.$emit('deleted')
          this.$toast.success(response.data.message)
          loader.hide()
          await this.fetchAssignedClients()
        }
      } catch (err: any) {
        // eslint-disable-line
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    async fetchAssignedClients(): Promise<void> {
      const response = await TenderService.fetchLimitedClients(this.procurementId)
      if (response?.data?.data) {
        this.assignedClients = response.data.data || []
      } else {
        this.assignedClients = []
        this.$toast.error(this.$t('could not fetch favorite clients') as string)
      }
    },
  },
  mounted() {
    if (this.procurementId === 0) {
      this.$toast.error(this.$t('somethingWentWrong') as string)
    } else {
      this.fetchAssignedClients()
    }
  },
})

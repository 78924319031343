

























import Vue from 'vue'
import TenderService from '@/services/tender'
import CardTitle from '@/components/CardTitle.vue'
import { errorMessages } from '@/utils/helpers'

export default Vue.extend({
  name: 'PublishButton',
  components: { CardTitle },
  model: { prop: 'showModal' },
  props: { id: { type: Number, default: 0 } },
  data: () => ({
    dialog: false,
    captcha: '',
    matchCaptcha: '',
  }),
  methods: {
    async publish() {
      const loader = this.$loading.show()
      try {
        const response = await TenderService.publish(this.id)

        if (response) {
          this.$emit('published')
          this.$toast.success(response.data.message)
          this.dialog = false
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    generateRandomCaptcha(length: number) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength))
      return result
    },
  },
  watch: {
    matchCaptcha: function () {
      if (this.captcha === this.matchCaptcha) {
        this.dialog = false
        this.publish()
      }
    },
    dialog: function () {
      if (this.dialog) this.captcha = this.generateRandomCaptcha(5)
    },
  },
})
